import * as types from "../action-types";

const initState = [];
export default function options(state = initState, action) {
  switch (action.type) {
    case types.OPTION:
      return state;
    default:
      return state;
  }
}
