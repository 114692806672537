import Clipboard from "clipboard";
import { message } from "antd";

const clipboardSuccess = () => {
  message.success("Copy success!");
}

const clipboardError = () => {
  message.error("Copy error!");
}

const handleClipboard = (text, event) => {
  
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  });
  clipboard.on("success", () => {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on("error", () => {
    clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
}

export default handleClipboard;
