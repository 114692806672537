export function debounce(func, wait, immediate) {

  let timeout, args, context, timestamp, result;

  const later = () => {

    const last = +new Date() - timestamp;

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
    
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return (...args) => {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
// Find the menuItem with the attribute value from the MenuList based on the attribute value
export function getMenuItemInMenuListByProperty(menuList, key, value) {
  let stack = [];
  stack = stack.concat(menuList);
  let res;
  while (stack.length) {
    let cur = stack.shift();
    if (cur.children && cur.children.length > 0) {
      stack = cur.children.concat(stack);
    }
    if (value === cur[key]) {
      res = cur;
    }
  }
  return res;
}

/**
 * @description 将时间戳转换为年-月-日-时-分-秒格式
 * @param {String} timestamp
 * @returns {String} 年-月-日-时-分-秒
 */

export function timestampToTime(timestamp) {
  var date = new Date(timestamp);
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
  var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
  var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
  var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
  
  let strDate = Y+M+D+h+m+s;
  return strDate;
}

export function removeHtmlTags(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const strippedString = div.textContent || div.innerText || '';
  return strippedString.trim();
}

export function formatTime(seconds) {
  if (seconds === '' || seconds === null || seconds === undefined) return ''
  
  var mins = Math.floor(seconds / 60);
  var secs = seconds % 60;

  if (mins >= 30) {
    var hours = Math.floor(mins / 60);
    mins = mins % 60;
    var formattedTime = hours + '個鐘';
  } else {
    var formattedTime = '';
  }

  if (mins > 0) {
    formattedTime += mins + '分鐘';
  }

  if (secs > 0) {
    formattedTime += secs + '秒';
  }

  return formattedTime;
};

export const mergeAndRemoveDuplicates = (arr1, arr2) => {
  const mergedArray = [...arr1, ...arr2];
  const uniqueSet = new Set(mergedArray);
  return Array.from(uniqueSet);
};

export const number_format = (
  number,
  decimals = 0,
  decimalSeparator = '.',
  thousandSeparator = ','
) => {
  decimals = decimals !== undefined ? decimals : 0;
  decimalSeparator = decimalSeparator !== undefined ? decimalSeparator : '.';
  thousandSeparator = thousandSeparator !== undefined ? thousandSeparator : ',';

  var parts = number.toFixed(decimals).split('.');
  var integerPart = parts[0];
  var decimalPart = parts.length > 1 ? decimalSeparator + parts[1] : '';

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  return integerPart + decimalPart;
};

