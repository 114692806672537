import * as types from "../action-types";
import { reqUserInfo } from "@/api/login";

export const getUserInfo = (token) => async (dispatch) => {
  try {
      let response = await reqUserInfo(token)
      const {success, data , errors} = response.data
      if (success) {
        // custom data
        const userInfo = {
          // token: token,
          profile: {
            id: data.id,
            name: data.name,
            avatar: data.avatar,
            // menus: data.menus.length > 0 ? data.menus.map(item => {
            //   return {
            //     id: item.id,
            //     title: item.title,
            //     path: item.path,
            //     icon: item.icon,
            //     order: item.order,
            //   }
            // }).sort((a, b) => {return a.order - b.order;}) : [], // order asc via order
          }
        }

        dispatch(setUserInfo(userInfo));
      }
      return response.data
     

  } catch (e) {
    console.log(e);
    return {
      success: false,
      errors: {
        message: e?.response?.data?.errors.message ?? e.message,
      }
    }
  }
};

export const setUserToken = (token) => {
  return {
    type: types.USER_SET_USER_TOKEN,
    token,
  };
};

export const setUserInfo = (userInfo) => {
  return {
    type: types.USER_SET_USER_INFO,
    ...userInfo,
  };
};

export const resetUser = () => {
  return {
    type: types.USER_RESET_USER,
  };
};
