import React from 'react';
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Layout } from 'antd';
import { getMenuItemInMenuListByProperty } from '@/utils';
import routeList from '@/config/routeMap';
// import menuList from '@/config/menuConfig';
import './index.less';
import { useSelector } from 'react-redux';

const { Content } = Layout;

const getPageTitle = (menuList, pathname) => {
  let title = '| Admin';
  let item = getMenuItemInMenuListByProperty(menuList, 'path', pathname);
  if (item) {
    title = `${item.label} | Admin`;
  }
  return title;
};

const LayoutContent = (props) => {
  const { location } = props;
  const { menus } = useSelector((state) => state.user.profile);
  const { pathname } = location;
  const handleFilter = (menuList, path) => {
    return (
      menuList.filter((item) => {
        if (item.children && item.children.length > 0) {
          return handleFilter(item.children, path);
        }
        return item.path === path;
      }).length > 0
    );
  };
  return (
    <DocumentTitle title={getPageTitle(menus, pathname)}>
      <Content style={{ height: 'calc(100% - 100px)' }}>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            timeout={500}
            classNames='fade'
            exit={false}
          >
            <Switch location={location}>
              <Redirect exact from='/' to={menus[0]?.path ?? `/blogs`} />
              {routeList.map((route) => {
                return (
                  handleFilter(menus, route.path) && (
                    <Route
                      exact
                      component={route.component}
                      key={route.path}
                      path={route.path}
                    />
                  )
                );
              })}
              <Redirect to='/error/404' />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Content>
    </DocumentTitle>
  );
};

export default connect((state) => state.user)(withRouter(LayoutContent));
