import React, { useState } from "react";
import { Drawer, Switch, Row, Col, Divider, Alert, Button } from "antd";
import { CopyOutlined, NotificationOutlined } from '@ant-design/icons';
import { toggleSettingPanel, changeSetting } from "@/store/actions";
import { useSelector, useDispatch } from "react-redux";
import clip from "@/utils/clipboard";

const RightPanel = () => {
  const settingPanelVisible = useSelector(state => state.app.settingPanelVisible);
  const defaultSidebarLogo = useSelector(state => state.settings.sidebarLogo);
  const defaultFixedHeader = useSelector(state => state.settings.fixedHeader);
  const defaultTagsView = useSelector(state => state.settings.tagsView);

  const [sidebarLogo, setSidebarLogo] = useState(defaultSidebarLogo);
  const [fixedHeader, setFixedHeader] = useState(defaultFixedHeader);
  const [tagsView, setTagsView] = useState(defaultTagsView);


  const dispatch = useDispatch();
  const toggleSetting = () => dispatch(toggleSettingPanel());
  const handleChangeSetting = (payload) => dispatch(changeSetting(payload));

  const sidebarLogoChange = (checked) => {
    setSidebarLogo(checked);
    handleChangeSetting({ key: "sidebarLogo", value: checked });
  };

  const fixedHeaderChange = (checked) => {
    setFixedHeader(checked);
    handleChangeSetting({ key: "fixedHeader", value: checked });
  };

  const tagsViewChange = (checked) => {
    setTagsView(checked);
    handleChangeSetting({ key: "tagsView", value: checked });
  };

  const handleCopy = (e) => {
    let config = `
    export default {
      showSettings: true,
      sidebarLogo: ${sidebarLogo},
      fixedHeader: ${fixedHeader},
      tagsView: ${tagsView},
    }
    `;
    clip(config, e);
  };

  return (
    <div className="rightSettings">
      <Drawer
        title="Settings"
        placement="right"
        width={350}
        onClose={toggleSetting}
        open={settingPanelVisible}
      >
        <Row>
          <Col span={12}>
            <span>Logo</span>
          </Col>
          <Col span={12}>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              defaultChecked={sidebarLogo}
              onChange={sidebarLogoChange}
            />
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Col span={12}>
            <span>Fixed Header</span>
          </Col>
          <Col span={12}>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              defaultChecked={fixedHeader}
              onChange={fixedHeaderChange}
            />
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Col span={12}>
            <span>Tags View</span>
          </Col>
          <Col span={12}>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              defaultChecked={tagsView}
              onChange={tagsViewChange}
            />
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Col span={24}>
            <Alert
              message="Note:"
              description="The config bar is only used for preview in the dev env, and will not be displayed in the prod env. Please manually modify the /src/defaultSettings.js config file after copying"
              type="warning"
              showIcon
              icon={<NotificationOutlined />}
              style={{ marginBottom: "16px" }}
            />
            <Button style={{ width: "100%" }} onClick={handleCopy}>
              <CopyOutlined /> Copy
            </Button>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default RightPanel;