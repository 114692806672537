import React from "react";
import { Layout } from "antd";
import Logo from "./Logo";
import Menu from "./Menu";
import { useSelector } from 'react-redux';
const { Sider } = Layout;

const LayoutSider = () => {
  const user = useSelector(state => state.user);
  const sidebarCollapsed = useSelector(state => state.app.sidebarCollapsed);
  const sidebarLogo = useSelector(state => state.settings.sidebarLogo);

  return (
    <Sider
      collapsible
      collapsed={sidebarCollapsed}
      trigger={null}
      style={{ zIndex: "10" }}
    >
      {sidebarLogo ? <Logo user={user} /> : null}
      <Menu />
    </Sider>
  );
};
export default LayoutSider;
