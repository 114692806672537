import React, { Component } from 'react';
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zh_TW from 'antd/locale/zh_TW';
import store from "./store";
import Router from "./router";
class App extends Component {
  
  render() { 
    return (
      <ConfigProvider
        locale={zh_TW}
        theme={{
          token: {
            colorPrimary: '#ff5e57',
          },
        }}
      >
        <Provider store={store}>
          <Router />
        </Provider>
      </ConfigProvider>
    );
  }
}
 
export default App;
