import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from 'antd';
import MenuIcon from './menu-icon';
import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { addTag } from '@/store/actions';
import { getMenuItemInMenuListByProperty } from '@/utils';
import './index.less';
import menuList from '@/config/menuConfig';

// Update order
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const AdminMenu = () => {
  const [menuTreeNode, setMenuTreeNode] = useState(null);
  const [openKey, setOpenKey] = useState([]);

  const { menus } = useSelector((state) => state.user.profile);

  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;

  const getMenuNodes = (menuList) => {
    const path = location.pathname;
    return menuList.reduce((pre, item) => {
      if (item?.children?.length === 0) {
        pre.push({
          label: (
            <Link to={item.path}>
              {item.icon ? <MenuIcon type={item.icon} /> : null}
              <span>{item.label}</span>
            </Link>
          ),
          key: item.path,
        });
      } else {
        // find a child item math with current path
        const cItem = item?.children?.find(
          (cItem) => path.indexOf(cItem.path) === 0
        );

        // If it exists, it means that the sublist of the current item needs to be opened
        if (cItem) {
          setOpenKey([...openKey, item.path]);
        }
        // <SubMenu>
        pre.push({
          label: (
            <span>
              {item.icon ? <MenuIcon type={item.icon} /> : null}
              <span>{item.label}</span>
            </span>
          ),
          key: item.path,
          children: getMenuNodes(item.children),
        });
      }
      return pre;
    }, []);
  };

  const handleMenuSelect = ({ key = '/blogs' }) => {
    let menuItem = getMenuItemInMenuListByProperty(menuList, 'path', key);
    dispatch(addTag(menuItem));
  };

  useEffect(() => {
    const menuTreeNode = getMenuNodes(menus);
    setMenuTreeNode(menuTreeNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='sidebar-menu-container'>
      <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
        {menuTreeNode &&
          menuTreeNode.map((item, index) => (
            <Menu
              key={index}
              mode='inline'
              theme='dark'
              onSelect={handleMenuSelect}
              selectedKeys={[path]}
              defaultOpenKeys={openKey}
              items={[item]}
            ></Menu>
          ))}
      </Scrollbars>
    </div>
  );
};

export default AdminMenu;
