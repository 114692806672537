import React, { useState, useEffect } from "react";
import screenfull from "screenfull";
import { message, Tooltip } from "antd";
import { FullscreenOutlined, FullscreenExitOutlined} from '@ant-design/icons';

import "./index.less";

const click = () => {
  if (!screenfull.isEnabled) {
    message.warning("Your browser not supports fullscreen");
    return false;
  }
  screenfull.toggle();
};

const FullScreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const change = () => {
    setIsFullscreen(screenfull.isFullscreen);
  };

  useEffect(() => {
    screenfull.isEnabled && screenfull.on("change", change);
    return () => {
      screenfull.isEnabled && screenfull.off("change", change);
    };
  }, []);

  const title = isFullscreen ? "Exit Full Screen" : "Full Screen";

  return (
    <div className="fullScreen-container">
      <Tooltip placement="bottom" title={title}>
        {isFullscreen ? <FullscreenExitOutlined  onClick={click} /> : <FullscreenOutlined  onClick={click} />}
      </Tooltip>
    </div>
  );
};

export default FullScreen;
