const menuList = [
  {
    label: 'Orders',
    path: '/orders',
    icon: 'ShoppingOutlined',
  },
  {
    label: 'Users',
    path: '/users',
    icon: 'UserOutlined',
  },
  {
    label: 'Lessons',
    path: '/lessons',
    icon: 'SketchOutlined',
  },
  {
    label: 'Grammars',
    path: '/grammars',
    icon: 'HddOutlined',
  },
  {
    label: 'Vocabulary',
    path: '/vocabularies',
    icon: 'HddOutlined',
  },

  {
    label: 'Practice',
    path: '/practices',
    icon: 'HddOutlined',
  },
  {
    label: 'Levels',
    path: '/levels',
    icon: 'DatabaseOutlined',
    children: [
      {
        label: 'Level 1',
        path: '/levels/level1',
      },
      {
        label: 'Level 2',
        path: '/levels/level2',
      },
      {
        label: 'Level 3',
        path: '/levels/level3',
      },
    ],
  },
  // {
  //   label: '日文知識+',
  //   path: '/knowledge',
  //   icon: 'BankOutlined',
  //   children: [
  //     {
  //       label: '質問一覧',
  //       path: '/knowledge/questions',
  //     },
  //     {
  //       label: '回答一覧',
  //       path: '/knowledge/answers',
  //     },
  //     {
  //       label: '講師一覧',
  //       path: '/knowledge/teachers',
  //     },
  //     {
  //       label: 'Search',
  //       path: '/knowledge/search',
  //     },
  //   ],
  // },
  {
    label: '日文學習',
    path: '/blogs',
    icon: 'ReadOutlined',
  },
  {
    label: 'Notifications',
    path: '/notifications',
    icon: 'NotificationOutlined',
  },
  {
    label: 'QA',
    path: '/qas',
    icon: 'QuestionCircleOutlined',
  },
  {
    label: 'Practice50',
    path: '/practices50',
    icon: 'HddOutlined',
  },
  {
    label: 'Talk with AI',
    path: '/talks',
    icon: 'WechatOutlined',
  },
  // {
  //   label: 'Database',
  //   path: '/options',
  //   icon: 'SettingOutlined',
  // },
];
export default menuList;
