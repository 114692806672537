import React from "react";
import logo from "@/assets/images/logo.svg";
import "./index.less";

const Logo = (props) => {
  const {user} = props;
  return (
    <div className="sidebar-logo-container">
      {/* <img src={logo} className="sidebar-logo" alt="logo" /> */}
      <h1 className="sidebar-title">{user.profile.name}</h1>
    </div>
  );
};

export default Logo;
