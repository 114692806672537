import request from '@/utils/request'

export function reqLogin(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}
export function reqRefreshToken(data) {
  return request({
    url: '/refresh-token',
    method: 'post',
    data,
  });
}
export function reqLogout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function reqUserInfo(data) {
  return request({
    url: '/profile',
    method: 'post',
    data,
  });
}