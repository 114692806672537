import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Form, Input, Button, message, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import DocumentTitle from 'react-document-title';
import { login } from '@/store/actions';
import { useSelector, useDispatch } from 'react-redux';
import './index.less';

const Login = (props) => {
  const { token } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  let history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleLogin = (email, password) => {
    setLoading(true);

    dispatch(login(email, password))
      .then((data) => {
        if (data.success) {
          message.success('成功');
        } else {
          message.error(data.errors.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const { email, password } = values;
        handleLogin(email, password);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
        message.error('Login failed!');
      });
  };

  if (token) {
    if (user?.profile?.menus) {
      return <Redirect to={user?.profile?.menus[0]?.path ?? '/blogs'} />;
    }
    // return <Redirect to='/users' />;
  }
  return (
    <DocumentTitle title={'Admin'}>
      <div className='login-container'>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            email: '',
            password: '',
          }}
          autoComplete='off'
          className='content'
        >
          <div className='title'>
            <h2>Admin</h2>
          </div>
          <Spin spinning={loading} tip='載入中...'>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '電子郵件是必填項目',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='電子郵件'
              />
            </Form.Item>

            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '密碼是必填項目',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type='password'
                placeholder='密碼'
              />
            </Form.Item>

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
              >
                會員登入
              </Button>
            </Form.Item>
          </Spin>
        </Form>
      </div>
    </DocumentTitle>
  );
};

export default Login;
