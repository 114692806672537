import React from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { toggleSiderBar } from "@/store/actions";
import { useSelector, useDispatch } from "react-redux";
import "./index.less";

const MenuCollapsed = (props) => {
  const sidebarCollapsed = useSelector(state => state.app.sidebarCollapsed);
  const dispatch = useDispatch();
  const handleToggleSiderBar = () => dispatch(toggleSiderBar())

  return (
    <div className="menu-collapsed-container">
      {sidebarCollapsed ? <MenuUnfoldOutlined onClick={handleToggleSiderBar} /> : <MenuFoldOutlined onClick={handleToggleSiderBar} />}
    </div>
  );
};

export default MenuCollapsed;
