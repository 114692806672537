import { setUserToken, resetUser, setUserInfo } from "./user";
import { reqLogin, reqLogout } from "@/api/login";
import { setToken, removeToken } from "@/utils/auth";
import { setUser, removeUser, setRefreshToken, removeRefreshToken } from '@/utils/localStorage';

export const login = (email, password) => async (dispatch) => {
  try {
    const response = await reqLogin({email, password})
    const {success, data, errors} = response.data
    if (success) {
      // custom data
      const userInfo = {
        token: data.token,
        profile: {
          id: data.user.id,
          name: data.user.name,
          avatar: data.user?.avatar?.thumbnail_80x80_webp,
          menus: data.user.menus || [], // order asc via order
        }
      }

      dispatch(setUserInfo(userInfo));

      // set in browser
      // setToken(data.token); // set token in server
      setRefreshToken(data.refresh_token);
      setUser(JSON.stringify(userInfo.profile))
    }
    return response.data

  } catch (e) {
    return {
      success: false,
      errors: {
        message: e?.response?.data?.errors.message || 'Server error'
      }
    }
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await reqLogout()
    const {success, data, errors} = response.data
    if (success) {
      console.log('logout')
      dispatch(resetUser());
      // removeToken(); // remove it in server
      removeUser(); 
      removeRefreshToken()
    } 
    return response.data

  } catch (e) {
    console.log(e);
    return {
      success: false,
      errors: {
        message: e?.response?.data?.errors.message || 'Server error',
      },
    };
  }
};
