const auth = 'auth'
const refreshToken = 'refresh_token';

const getUser = () => {
  return JSON.parse(localStorage.getItem(auth) ?? '[]')
}
const setUser = (user) => {
  return localStorage.setItem(auth, user)
}
const removeUser = () => {
  return localStorage.removeItem(auth)
}
const getRefreshToken = () => {
  return localStorage.getItem(refreshToken);
};

const setRefreshToken = (token) => {
   return localStorage.setItem(refreshToken, token);
}
const removeRefreshToken = () => {
  return localStorage.removeItem(refreshToken);
};
export {
  getUser,
  setUser,
  removeUser,
  setRefreshToken,
  removeRefreshToken,
  getRefreshToken,
};
