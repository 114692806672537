import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Tag } from "antd";
import { deleteTag, emptyTaglist, closeOtherTags } from "@/store/actions";

const TagList = () => {

  const tagListContainer = useRef(null);
  const contextMenuContainer = useRef(null);
  const history = useHistory();

  const currentPath = history.location.pathname;
  const [state, setState] = useState({left: 0, top: 0, menuVisible: false, currentPath: currentPath});

  const dispatch = useDispatch();
  const taglist = useSelector(state => state.tagsView.taglist)

  const handleClose = (tag) => {
    const path = tag.path;
    const { currentPath } = state;
    const length = taglist.length;
    // If the current page is closed, jump to the last tag
    if (path === currentPath) {
      history.push(taglist[length - 1].path);
    }
    // If the last tag is closed, and the page corresponding to the last tag is currently displayed, the routing jump is made
    if (
      path === taglist[length - 1].path &&
      currentPath === taglist[length - 1].path
    ) {

      // Because cutTaglist is executed at the end, jump to the corresponding route of the previous tags, which should be -2
      if (length - 2 > 0) {
        history.push(taglist[length - 2].path);
      } else if (length === 2) {
        history.push(taglist[0].path);
      }
    }

    // First jump to the route, and then modify the taglist of the state tree
    dispatch(deleteTag(tag));
  };
  const handleClick = (path) => {
    history.push(path);
    setState({
      ...state,
      currentPath: path
    })
  };

  const openContextMenu = (tag, event) => {
    event.preventDefault();
    const menuMinWidth = 105;
    const clickX = event.clientX;
    const clickY = event.clientY;
    const clientWidth = tagListContainer.current.clientWidth; // container width
    const maxLeft = clientWidth - menuMinWidth; // left boundary

    // When the mouse click position is larger than the left border, it means that the mouse click position is to the right, and the menu is placed on the left
    if (clickX > maxLeft) {
      setState({
        ...state,
        left: clickX - menuMinWidth + 15,
        top: clickY,
        menuVisible: true,
        currentPath: tag.path,
      });
    } else {
      // Conversely, when the mouse click position is to the left, the menu is placed on the right
      setState({
        ...state,
        left: clickX,
        top: clickY,
        menuVisible: true,
        currentPath: tag.path,
      });
    }
  };
  const handleClickOutside = (event) => {
    const { menuVisible } = state;
    const isOutside = !(
      contextMenuContainer.current &&
      contextMenuContainer.current.contains(event.target)
    );
    if (isOutside && menuVisible) {
      closeContextMenu();
    }
  };

  const closeContextMenu = () => {
    setState({
      ...state,
      menuVisible: false
    })
  }

  const handleCloseAllTags = () => {
    dispatch(emptyTaglist());
    history.push("/dashboard");
    closeContextMenu();
  };

  const handleCloseOtherTags = () => {
    const currentTag = state.currentTag;
    const { path } = currentTag;
    dispatch(closeOtherTags(currentTag))
    history.push(path);
    closeContextMenu();
  };

  useEffect((handleClickOutside) => {
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    }
  }, [])

  return (
    <>
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          hideTracksWhenNotNeeded={true}
          renderView={(props) => (
            <div {...props} className="scrollbar-container" />
          )}
          renderTrackVertical={(props) => (
            <div {...props} className="scrollbar-track-vertical" />
          )}
        >
          <ul className="tags-wrap" ref={tagListContainer}>
            {taglist.map((tag) => (
              <li key={tag.path}>
                <Tag
                  onClose={() => handleClose(tag)}
                  closable={tag.path !== "/dashboard"}
                  color={currentPath === tag.path ? "blue" : ""}
                  onClick={() => handleClick(tag.path)}
                  onContextMenu={() => openContextMenu(tag)}
                >
                  {tag.title}
                </Tag>
              </li>
            ))}
          </ul>
        </Scrollbars>
        {state.menuVisible ? (
          <ul
            className="contextmenu"
            style={{ left: `${state.left}px`, top: `${state.top}px` }}
            ref={contextMenuContainer}
          >
            <li onClick={() => handleCloseOtherTags()}>Close Others</li>
            <li onClick={() => handleCloseAllTags()}>Close All</li>
          </ul>
        ) : null}
      </>
  ) 

}
export default TagList;
