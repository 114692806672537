import axios from "axios";
import store from "@/store";
import { Modal, message } from "antd";
import { getToken, removeToken } from '@/utils/auth';
import { logout } from "@/store/actions";
import { getRefreshToken, removeUser } from '@/utils/localStorage';
import { removeRefreshToken, setRefreshToken } from './localStorage';
import { reqRefreshToken } from "../api/login";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

//Create an axios example
const service = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL_API, // api base_url
  headers: {
    'Content-Type': 'application/json'
  },
  crossDomain: true,
  withCredentials: true,
  timeout: 12000, // request timeout
});

// Request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // if (store.getState().user.token) {
    //   config.headers.Authorization = 'Bearer ' + getToken();
    // }
    return config;
  },
  (error) => {
   
    // Do something with request error
    console.log(error);
    Promise.reject(error);
  }
);

// Response interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(() => {
          return axios(originalRequest);
        });
      }

      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        reqRefreshToken({ refresh_token: refreshToken })
          .then((res) => {
            const response = res.data;
            if (response.success) {
              const { refresh_token } = response.data;
              setRefreshToken(refresh_token); // Assuming this also sets the cookie
              processQueue(null);
              resolve(axios(originalRequest));
            } else {
              processQueue(response.errors.message || 'Server error');
              removeRefreshToken();
              removeUser();
              window.location.href = '/login';
              reject(error);
            }
          })
          .catch((err) => {
            processQueue(err);
            removeRefreshToken();
            removeUser();
            window.location.href = '/login';
             reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default service;
