import React from 'react';
import { Dropdown, Modal, Layout, Avatar } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { logout } from '@/store/actions';
import FullScreen from '@/components/FullScreen';
import MenuCollapsed from '@/components/MenuCollapsed';
import { useSelector, useDispatch } from 'react-redux';

import './index.less';
const { Header } = Layout;

const LayoutHeader = () => {
  const { token } = useSelector((state) => state.user);
  const { avatar } = useSelector((state) => state.user.profile);
  const sidebarCollapsed = useSelector((state) => state.app.sidebarCollapsed);
  const { fixedHeader, showSettings } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const handleLogout = (token) => {
    Modal.confirm({
      title: '登出',
      content: '你確定嗎？',
      okText: '好的',
      cancelText: '取消',
      onOk: () => {
        dispatch(logout());
      },
    });
  };
  const items = [
    // { label: (<Link to="/dashboard">Dashboard</Link>), key: 'dashboard' },
    { label: '登出', key: 'logout' },
  ];

  const onClick = ({ key }) => {
    switch (key) {
      case 'logout':
        dispatch(logout());
        // handleLogout(token);
        break;
      default:
        break;
    }
  };

  const computedStyle = () => {
    let styles;
    if (fixedHeader) {
      if (sidebarCollapsed) {
        styles = {
          width: 'calc(100% - 80px)',
        };
      } else {
        styles = {
          width: 'calc(100% - 200px)',
        };
      }
    } else {
      styles = {
        width: '100%',
      };
    }
    return styles;
  };
  return (
    <>
      {fixedHeader ? <Header /> : null}
      <Header
        style={computedStyle()}
        className={fixedHeader ? 'fix-header' : ''}
      >
        <MenuCollapsed />
        {/* <BreadCrumb /> */}
        <div className='right-menu'>
          <FullScreen />
          {/* {showSettings ? <Settings /> : null} */}
          <div className='dropdown-wrap'>
            <Dropdown menu={{ items, onClick }}>
              <div>
                <Avatar
                  shape='square'
                  size='medium'
                  src={`${process.env.REACT_APP_S3_URL}${avatar}`}
                />
                <CaretDownOutlined style={{ color: 'rgba(0,0,0,.3)' }} />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
    </>
  );
};

export default LayoutHeader;
